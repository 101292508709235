import React from "react"

import Header from "../components/Header"
import Footer from "../components/Footer"
import ContentImage from "../components/ContentImage"
import Reviews from "../components/Reviews"
import { OgTags } from "../components/OpenGraph";

const cta = {
  hasBackground: false,
  hasButton: true,
  imagePrimary: false,
  isFullContent: true,
  styleA: true,
  title: "We've helped some of the world-class entrepreneurs & tech companies increase efficiency & reduce development costs.",
  image: require('../assets/images/banner-dev.png'),
  buttons: [
    {
      type: 'normal',
      text: 'Book a call',
      link: 'https://calendly.com/fullstackhq/meeting',
      beacon: false,
      external: true
    }, {
      type: 'outline',
      text: 'Chat with us',
      link: '/',
      beacon: true
    }
  ]
}

const reviews = [
  {
    image: require('../assets/images/morgan-howson.png'),
    name: 'Morgan Howson',
    position:'Billsby, Head of Product Strategy and Business Development',
    statement: '“Fullstack are our secret weapon, and core to the success of our business. From deploying pixel perfect marketing sites, to creating our internal business intelligent dashboard, through to developing helpful tools and resources for our customers, we’ve yet to discover a task we can’t deploy to the team whilst being confident of their ability to deliver an excellent end result.”'
  }, {
    image: require('../assets/images/troy-parsons-lg.png'),
    name: 'Troy Parson',
    position:'AAR Auto Glass, Owner',
    statement: '“I have been doing business with Fullstack HQ for over 5 years and I can confidently say that they are the best in the business. Their customer service is outstanding and their team of experts on Wordpress is top-notch. Despite my lack of knowledge in web design, they were more than happy to answer all of my questions, no matter how trivial. They have built two different websites for my businesses and both have been a huge success. Their work is excellent and their prices are extremely affordable. It has been a pleasure working with them and they are always courteous and professional. Overall, a fantastic experience and I look forward to many more years of working with them. I would highly recommend them to anyone in need of web design services. They are the best in the business!”'
  }, {
    image: require('../assets/images/juan-rivas.png'),
    name: 'Juan Rivas',
    position:'Explore America, VP of Operations',
    statement: '“Team, I can’t thank y’all enough. I am absolutely thrilled with the hardworking team from FullStack. What y’all have helped us accomplish over the last few months is astonishing! I am very glad our paths crossed. Just wanted to make sure y’all know how much we value your hard work. We are very excited to continue working together and accomplishing new goals!🎉🙏”'
  }, {
    image: require('../assets/images/kevin-kaninng-lg.png'),
    name: 'Kevin Canning',
    position:'Pearls of Joy, Founder',
    statement: '“We’ve had a record year for both brands and a big part of that is the site improvements we’ve been able to make.  It’s amazing that I have a team of world-class developers available whenever I need.  I recommend your team often to colleagues”'
  }, {
    image: require('../assets/images/frank-campanella-lg.png'),
    name: 'Frank Campanella',
    position:'Narrative I/O',
    statement: '“The folks at PSD Wizard and FullStack HQ are great to work with. We needed a quick turnaround for a website and they pulled off a pixel perfect execution and quickly responded to feedback & page design updates. And despite being on the other side of the world, are responsive communicators and ver organized. They’re also very knowledgeable and provided detailed information and reccomendations for website integrations and deployment throughout the dvelopment process. Highly recommended!”'
  }, {
    image: require('../assets/images/mohammed-abid-lg.png'),
    name: 'Mohammed Abid',
    position:'Facebook, Product Designer',
    statement: '“Thank you so much for helping out with the tonigh/tomorrow urgency if possible- I really appreciate it! I also recommended Mary that you use a better quote that is better for your team :) I was incredibly impressed by how you matched specs with clean code. And the team is super easy to work with!”'
  }, {
    image: require('../assets/images/jeff-bronson-lg.png'),
    name: 'Jeff Bronson',
    position:'CX Strategist, Copious',
    statement: '“So far we’ve been having a wonderful experinece with Fullstack for both Eventbuilder and Jugs Sports. Your communication is succint and things are getting done. We’ere excited to soon start the Copious Iinternal website, as well partner with you again for future projects.”'
  }, {
    image: require('../assets/images/nish-menon-lg.png'),
    name: 'Nish Menon',
    position:'My Muscle Chef, Founder',
    statement: '“Guys, I forgot to thank you for the prompt responses and awesome work. Things are looking good so far. I look forward to working with you guys in the future.”'
  }, {
    image: require('../assets/images/samuel-darcy-lg.png'),
    name: 'Samuel Darcy',
    position:'Serge DeNimes, Marketing Director',
    statement: '“Haha! I do! I manage lots of people’s websites and investors are quite unforgiving sometimes so I may come accross brief - Sorry! You guys are some of the best developers I have worked with so have faith in that.”'
  }, {
    image: require('../assets/images/jay-geraghty-lg.png'),
    name: 'Jay Geraghty',
    position:'The Physique Geek, Director',
    statement:
    <span> “Honestly, Im really happy with the site, looks superb :), thank you.
    <br/><br/>
    I have to say your team and everyone working on my website project have been outstanding, and a special mention for Pamela, she has been exceptional, been pleasure working closely with her. What a great asset to your team.
    <br/><br/>
    Absolutely, I didn’t expect that additional work for free, so yes, add to final invoice.”</span>
  }, {
    image: require('../assets/images/renata-reis-lg.png'),
    name: 'Renata Reis',
    position:'collective.care, Marketing Manager',
    statement: '“First of all, I just want to say a big thank you for the quality and efficiency of your team’s work. Pamela has been really amazing and prompt with all projects and tasks!”'
  }, {
    image: require('../assets/images/shichao-zhou-lg.png'),
    name: 'Shichao Zhou',
    position:'G-24 Secretariat, Research Officer',
    statement: '“I would like to sincerelt thank you for the excellent work you have done in developing and maintaining the G-24 website. I appreciate all the help from your team in the past two years. Thank you for your support and I wish you all the best.”'
  }, {
    image: require('../assets/images/james-brown-lg.png'),
    name: 'James Brown',
    position:'Crafter’s Companion, Head of Digital',
    statement: '“First of all, I’d like to say what a great job you’ve done on the Crafter’s Companion US Website. You’ve really opened my eyes on what’s possible with 3DCart! Having seen what your team are able to do with 3DCart, I was wondering if you would be interested in doing a much smaller job for me?”'
  }, {
    image: require('../assets/images/matt-joseph-lg.png'),
    name: 'Matt Joseph',
    position:'Rawmate, Founder',
    statement:
    <span>“Looks really good Peng! Nice work and congrats. I’m glad Kevin is happy with the work. It looks great. Just like their old site. I too love the Turbo theme. If I thought that was possible with Rawmate, I’d have requested to make sure Turbo theme was used well. Turbo is really fast, it converts well but it is also 3rd party theme external to Shopify.
    <br/><br/>
    Love your work Peng. Really happy to refer Fullstack whenever I have the opportunity.
    ”</span>
  }, {
    image: require('../assets/images/ashley-mcnamara-lg.png'),
    name: 'Ashley McNamara',
    position:'PurePearls.com, CEO',
    statement: 'Thank YOU Pamela - the site looks AMAZING so far! I know we’re all working feverishly to get last minute touches and fix bugs, but wow I am pleased!'
  }, {
    image: require('../assets/images/steve-sperandeo.png'),
    name: 'Steve Sperandeo',
    position:'Group Photo, Founder',
    statement: '“I’m a real customer of Peng’s. Him and his team are awesome. Highly recommend.”'
  }, {
    image: require('../assets/images/ari-mir.png'),
    name: 'Ari Mir',
    position:'Clutter, CEO',
    statement: '“This is a great start and I think we’re going to have a lot more business to send your way!”'
  }, {
    image: require('../assets/images/tim-haskins-lg.png'),
    name: 'Tim Haskins',
    position:'UpliftCX, CEO',
    statement: '“The client (and me) are thrilled with the end result and are thankful for you and your team’s great skills, communication, timeless, and quality of work!”'
  }, {
    image: require('../assets/images/elizabeth-fowkes-lg.png'),
    name: 'Elizabeth Fowkes',
    position:'CyberTalks, Marketing Executive',
    statement: '“Thanks for all your sterling work on the platform - its looking really good.”'
  }, {
    image: require('../assets/images/julie-grant-lg.png'),
    name: 'Julie Grant',
    position: "Mandala Dream Co, Creator",
    statement: '“It looks great, if that’s what you recommend I’m sure you’ll be able to do a beautiful job with that.”'
  }, {
    image: require('../assets/images/adam-phillips-lg.png'),
    name: 'Adam Phillips',
    position: "LifeGrip, Founder",
    statement: '“Great Mary, the site of looking awesome!!! I’m stoked!”'
  }
]

const ctaHelp = {
  hasBackground: true,
  hasButton: true,
  styleB: true,
  title: 'Need some help?',
  desc: `We know trying a new service can be scary, that's why we want to make this 100% risk-free for you and your company.`,
  image: require('../assets/images/mascot-cta.png'),
  buttons: [
    {
      type: 'normal',
      text: 'Book a call',
      link: 'https://calendly.com/fullstackhq/meeting',
      beacon: false,
      external: true
    }, {
      type: 'outline',
      text: 'Chat with us',
      link: '/',
      beacon: true
    }
  ]
}

function CustomerReview() {
  return (
    <>
      <OgTags
        ogTitle="Fullstack HQ Reviews: What You Think About Us"
        ogDescription="Don't just listen to us see what other people think about Fullstack HQ by reading our customer reviews."
        ogUrl="https://fullstackhq.com/fullstackhq-reviews"
      />
      <Header />
        <main className="customer-review initialized-header">
          <ContentImage
            content={ cta }
          />

          <Reviews content={ reviews } />

          <ContentImage content={ ctaHelp } />
        </main>
      <Footer />
    </>
  )
}

export default CustomerReview
