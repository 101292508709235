import React from "react"

function Reviews(props) {
  const {content} = props

  return (
    <div className="section-reviews">
      <div className="container">
        <div className="cards">
            { content.map((item,i) => {
            return (
            <div className="reviews-wrapper" key={i}>
              <div className="person-holder">
                <img src={ item.image } className="person-image" alt={item.name} />
                <div className="person-content">
                  <p className="person-name"> { item.name } </p>
                  <p className="person-position"> { item.position } </p>
                </div>
              </div>
              <div className="statement-holder">
               <p className="statement-text"> { item.statement } </p>
              </div>
            </div>
            )
            }) }
        </div>
      </div>
    </div>
  )
}
  
export default Reviews